import React from 'react';
import styles from "./gallery.module.css"

const Gallery = ({children}) => {
    return (
        <ul className={styles.wrapper}>
            {children}
        </ul>
    );
};

export default Gallery;