import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/Gallery"
import GalleryItem from "../components/GalleryItem"
import { animals } from "../data/animals"

const GalleryPage = () => (
  <Layout>
    <SEO title="GackGack.de" />
    <Gallery>
        {animals.map(({ image, audio }, index) => (
            <GalleryItem
                key={index}
                imgSrc={image}
                audioSrc={audio}
            />
        ))}

      <GalleryItem>Saepe, blanditiis quam!</GalleryItem>
      <GalleryItem>Cum, eos reprehenderit.</GalleryItem>
      <GalleryItem>Alias, saepe asperiores?</GalleryItem>
      <GalleryItem>Fuga, at exercitationem.</GalleryItem>
      <GalleryItem>Nihil, magnam! Officia!</GalleryItem>
      <GalleryItem>Voluptas, laudantium natus!</GalleryItem>
      <GalleryItem>Tempora, quibusdam sed.</GalleryItem>
      <GalleryItem>Asperiores, nisi repudiandae.</GalleryItem>
      <GalleryItem>Soluta, quas voluptatibus.</GalleryItem>
      <GalleryItem>Ipsam, totam saepe.</GalleryItem>
      <GalleryItem>Debitis, asperiores ipsum.</GalleryItem>
      <GalleryItem>Aliquam, minus corporis.</GalleryItem>
      <GalleryItem>Illum, voluptatem maxime.</GalleryItem>
      <GalleryItem>Qui, explicabo odio!</GalleryItem>
      <GalleryItem>Iusto, nihil illum.</GalleryItem>
      <GalleryItem>Iure, numquam facere.</GalleryItem>
      <GalleryItem>Possimus, non eos!</GalleryItem>
      <GalleryItem>Nesciunt, quas consectetur.</GalleryItem>
      <GalleryItem>Accusantium, earum autem!</GalleryItem>
      <GalleryItem>Tenetur, asperiores ullam.</GalleryItem>
      <GalleryItem>Facilis, adipisci repudiandae.</GalleryItem>
      <GalleryItem>Neque, doloribus eligendi!</GalleryItem>
      <GalleryItem>Perferendis, vitae suscipit.</GalleryItem>
      <GalleryItem>Doloremque, aperiam vitae?</GalleryItem>
      <GalleryItem>Assumenda, obcaecati molestias.</GalleryItem>
      <GalleryItem>Officiis, laborum omnis!</GalleryItem>
      <GalleryItem>Blanditiis, itaque vitae.</GalleryItem>
      <GalleryItem>Quis, impedit cumque!</GalleryItem>
      <GalleryItem>Omnis, aut repudiandae.</GalleryItem>
      <GalleryItem>Laboriosam, quidem ullam!</GalleryItem>
      <GalleryItem>Nihil, neque velit?</GalleryItem>
      <GalleryItem>Aperiam, voluptatum eligendi.</GalleryItem>
      <GalleryItem>Ratione, neque debitis!</GalleryItem>
      <GalleryItem>Sunt, ex exercitationem.</GalleryItem>
      <GalleryItem>Est, molestias eius.</GalleryItem>
      <GalleryItem>Praesentium, nulla molestiae.</GalleryItem>
      <GalleryItem>Illum, at nobis!</GalleryItem>
      <GalleryItem>Voluptate, tenetur minima!</GalleryItem>
      <GalleryItem>Atque, fugit cum?</GalleryItem>
      <GalleryItem>Lorem, ipsum dolor.</GalleryItem>
      <GalleryItem>Saepe, blanditiis quam!</GalleryItem>
      <GalleryItem>Cum, eos reprehenderit.</GalleryItem>
      <GalleryItem>Alias, saepe asperiores?</GalleryItem>
      <GalleryItem>Fuga, at exercitationem.</GalleryItem>
      <GalleryItem>Nihil, magnam! Officia!</GalleryItem>
      <GalleryItem>Voluptas, laudantium natus!</GalleryItem>
      <GalleryItem>Tempora, quibusdam sed.</GalleryItem>
      <GalleryItem>Asperiores, nisi repudiandae.</GalleryItem>
      <GalleryItem>Soluta, quas voluptatibus.</GalleryItem>
      <GalleryItem>Ipsam, totam saepe.</GalleryItem>
      <GalleryItem>Debitis, asperiores ipsum.</GalleryItem>
      <GalleryItem>Aliquam, minus corporis.</GalleryItem>
      <GalleryItem>Illum, voluptatem maxime.</GalleryItem>
      <GalleryItem>Qui, explicabo odio!</GalleryItem>
      <GalleryItem>Iusto, nihil illum.</GalleryItem>
      <GalleryItem>Iure, numquam facere.</GalleryItem>
      <GalleryItem>Possimus, non eos!</GalleryItem>
      <GalleryItem>Nesciunt, quas consectetur.</GalleryItem>
      <GalleryItem>Accusantium, earum autem!</GalleryItem>
      <GalleryItem>Tenetur, asperiores ullam.</GalleryItem>
      <GalleryItem>Facilis, adipisci repudiandae.</GalleryItem>
      <GalleryItem>Neque, doloribus eligendi!</GalleryItem>
      <GalleryItem>Perferendis, vitae suscipit.</GalleryItem>
      <GalleryItem>Doloremque, aperiam vitae?</GalleryItem>
      <GalleryItem>Assumenda, obcaecati molestias.</GalleryItem>
      <GalleryItem>Officiis, laborum omnis!</GalleryItem>
      <GalleryItem>Blanditiis, itaque vitae.</GalleryItem>
      <GalleryItem>Quis, impedit cumque!</GalleryItem>
      <GalleryItem>Omnis, aut repudiandae.</GalleryItem>
      <GalleryItem>Laboriosam, quidem ullam!</GalleryItem>
      <GalleryItem>Nihil, neque velit?</GalleryItem>
      <GalleryItem>Aperiam, voluptatum eligendi.</GalleryItem>
      <GalleryItem>Ratione, neque debitis!</GalleryItem>
      <GalleryItem>Sunt, ex exercitationem.</GalleryItem>
      <GalleryItem>Est, molestias eius.</GalleryItem>
      <GalleryItem>Praesentium, nulla molestiae.</GalleryItem>
      <GalleryItem>Illum, at nobis!</GalleryItem>
      <GalleryItem>Voluptate, tenetur minima!</GalleryItem>
      <GalleryItem>Atque, fugit cum?</GalleryItem>
      <GalleryItem>Lorem, ipsum dolor.</GalleryItem>
      <GalleryItem>Saepe, blanditiis quam!</GalleryItem>
      <GalleryItem>Cum, eos reprehenderit.</GalleryItem>
      <GalleryItem>Alias, saepe asperiores?</GalleryItem>
      <GalleryItem>Fuga, at exercitationem.</GalleryItem>
      <GalleryItem>Nihil, magnam! Officia!</GalleryItem>
      <GalleryItem>Voluptas, laudantium natus!</GalleryItem>
      <GalleryItem>Tempora, quibusdam sed.</GalleryItem>
      <GalleryItem>Asperiores, nisi repudiandae.</GalleryItem>
      <GalleryItem>Soluta, quas voluptatibus.</GalleryItem>
      <GalleryItem>Ipsam, totam saepe.</GalleryItem>
      <GalleryItem>Debitis, asperiores ipsum.</GalleryItem>
      <GalleryItem>Aliquam, minus corporis.</GalleryItem>
      <GalleryItem>Illum, voluptatem maxime.</GalleryItem>
      <GalleryItem>Qui, explicabo odio!</GalleryItem>
      <GalleryItem>Iusto, nihil illum.</GalleryItem>
      <GalleryItem>Iure, numquam facere.</GalleryItem>
      <GalleryItem>Possimus, non eos!</GalleryItem>
      <GalleryItem>Nesciunt, quas consectetur.</GalleryItem>
      <GalleryItem>Accusantium, earum autem!</GalleryItem>
      <GalleryItem>Tenetur, asperiores ullam.</GalleryItem>
      <GalleryItem>Facilis, adipisci repudiandae.</GalleryItem>
      <GalleryItem>Neque, doloribus eligendi!</GalleryItem>
      <GalleryItem>Perferendis, vitae suscipit.</GalleryItem>
      <GalleryItem>Doloremque, aperiam vitae?</GalleryItem>
      <GalleryItem>Assumenda, obcaecati molestias.</GalleryItem>
      <GalleryItem>Officiis, laborum omnis!</GalleryItem>
      <GalleryItem>Blanditiis, itaque vitae.</GalleryItem>
      <GalleryItem>Quis, impedit cumque!</GalleryItem>
      <GalleryItem>Omnis, aut repudiandae.</GalleryItem>
      <GalleryItem>Laboriosam, quidem ullam!</GalleryItem>
      <GalleryItem>Nihil, neque velit?</GalleryItem>
      <GalleryItem>Aperiam, voluptatum eligendi.</GalleryItem>
      <GalleryItem>Ratione, neque debitis!</GalleryItem>
      <GalleryItem>Sunt, ex exercitationem.</GalleryItem>
      <GalleryItem>Est, molestias eius.</GalleryItem>
      <GalleryItem>Praesentium, nulla molestiae.</GalleryItem>
      <GalleryItem>Illum, at nobis!</GalleryItem>
      <GalleryItem>Voluptate, tenetur minima!</GalleryItem>
      <GalleryItem>Atque, fugit cum?</GalleryItem>
      <GalleryItem>Lorem, ipsum dolor.</GalleryItem>
      <GalleryItem>Saepe, blanditiis quam!</GalleryItem>
      <GalleryItem>Cum, eos reprehenderit.</GalleryItem>
      <GalleryItem>Alias, saepe asperiores?</GalleryItem>
      <GalleryItem>Fuga, at exercitationem.</GalleryItem>
      <GalleryItem>Nihil, magnam! Officia!</GalleryItem>
      <GalleryItem>Voluptas, laudantium natus!</GalleryItem>
      <GalleryItem>Tempora, quibusdam sed.</GalleryItem>
      <GalleryItem>Asperiores, nisi repudiandae.</GalleryItem>
      <GalleryItem>Soluta, quas voluptatibus.</GalleryItem>
      <GalleryItem>Ipsam, totam saepe.</GalleryItem>
      <GalleryItem>Debitis, asperiores ipsum.</GalleryItem>
      <GalleryItem>Aliquam, minus corporis.</GalleryItem>
      <GalleryItem>Illum, voluptatem maxime.</GalleryItem>
      <GalleryItem>Qui, explicabo odio!</GalleryItem>
      <GalleryItem>Iusto, nihil illum.</GalleryItem>
      <GalleryItem>Iure, numquam facere.</GalleryItem>
      <GalleryItem>Possimus, non eos!</GalleryItem>
      <GalleryItem>Nesciunt, quas consectetur.</GalleryItem>
      <GalleryItem>Accusantium, earum autem!</GalleryItem>
      <GalleryItem>Tenetur, asperiores ullam.</GalleryItem>
      <GalleryItem>Facilis, adipisci repudiandae.</GalleryItem>
      <GalleryItem>Neque, doloribus eligendi!</GalleryItem>
      <GalleryItem>Perferendis, vitae suscipit.</GalleryItem>
      <GalleryItem>Doloremque, aperiam vitae?</GalleryItem>
      <GalleryItem>Assumenda, obcaecati molestias.</GalleryItem>
      <GalleryItem>Officiis, laborum omnis!</GalleryItem>
      <GalleryItem>Blanditiis, itaque vitae.</GalleryItem>
      <GalleryItem>Quis, impedit cumque!</GalleryItem>
      <GalleryItem>Omnis, aut repudiandae.</GalleryItem>
      <GalleryItem>Laboriosam, quidem ullam!</GalleryItem>
      <GalleryItem>Nihil, neque velit?</GalleryItem>
      <GalleryItem>Aperiam, voluptatum eligendi.</GalleryItem>
      <GalleryItem>Ratione, neque debitis!</GalleryItem>
      <GalleryItem>Sunt, ex exercitationem.</GalleryItem>
      <GalleryItem>Est, molestias eius.</GalleryItem>
      <GalleryItem>Praesentium, nulla molestiae.</GalleryItem>
      <GalleryItem>Illum, at nobis!</GalleryItem>
      <GalleryItem>Voluptate, tenetur minima!</GalleryItem>
      <GalleryItem>Atque, fugit cum?</GalleryItem>
    </Gallery>
  </Layout>
)

export default GalleryPage
