import React from 'react';
import styles from "./galleryItem.module.css"
import AudioPlayer from './AudioPlayer';
import { colors } from '../data/colors';
import { getRandomArrayItem } from '../utils/helpers';

const GalleryItem = ({imgSrc='none', audioSrc}) => {
    const bgColor = getRandomArrayItem(colors);
    const buttonPos = getRandomArrayItem(['tr', 'tl', 'br', 'bl']);

    return (
        <li
            className={styles.wrapper}
            style={{
                backgroundColor: bgColor,
            }}
        >
            <div
                className={styles.bgWrapper}
                style={{
                    backgroundImage: `url(${imgSrc})`,
                }}
            >
                {audioSrc && <AudioPlayer audioSrc={audioSrc} buttonPos={buttonPos} />}
            </div>
        </li>
    );
};

export default GalleryItem;

