import React, { useState, useEffect, useRef } from "react"
import styles from "./audioPlayer.module.css";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import * as classNames from 'classnames';

const ICON_SIZE = 40;


const AudioPlayer = ({audioSrc, buttonPos}) => {
    const buttonStyles = classNames(styles.button, {
        [styles.tr]: buttonPos === 'tr',
        [styles.tl]: buttonPos === 'tl',
        [styles.bl]: buttonPos === 'bl',
        [styles.br]: buttonPos === 'br',
    });

    console.log(audioSrc);

    const [playing, setPlaying] = useState(false);

    const audioElement = useRef();

    const toggle = () => setPlaying(!playing)

    useEffect(() => {
        playing ? audioElement.current.play() : audioElement.current.pause()
    }, [playing])

    return (
        <>
            <audio ref={audioElement} src={audioSrc}></audio>
            <button className={buttonStyles} onClick={toggle}>{playing ? <PauseIcon  style={{ fontSize: ICON_SIZE }} /> : <PlayArrowIcon  style={{ fontSize: ICON_SIZE }} />}</button>
        </>
    );
};

export default AudioPlayer;